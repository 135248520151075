import { NgOptimizedImage } from '@angular/common';
import { Component, HostBinding, effect, inject, input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import {
  addAnimation,
  fadeAnimation,
  fadeStaggerFastAnimation,
  removeAnimation,
  slideUpAnimation,
  valueChangedFadeAnimation,
} from 'src/app/animations';
import { MAIN_LINKS, SETTINGS_LINKS, VENUE_LINKS, WAYTR } from 'src/app/constants';
import { compareModelsFn } from 'src/app/helpers';
import { AppLinksTypeModel, IconTypeEnum, PagesEnum, VenueModelResponseDto } from 'src/app/models';
import { VenueService } from 'src/app/services';
import { WaytrIcon } from 'src/app/shared';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    NzSkeletonModule,
    NzToolTipModule,
    NzIconModule,
    NzMenuModule,
    RouterLink,
    RouterLinkActive,
    WaytrIcon,
    NzLayoutModule,
    NzPageHeaderModule,
    TranslateModule,
  ],
  templateUrl: './app-side-menu.component.html',
  styleUrls: ['./app-side-menu.component.scss'],
  animations: [fadeStaggerFastAnimation, addAnimation, removeAnimation, fadeAnimation, slideUpAnimation, valueChangedFadeAnimation],
})
export class AppSideMenuComponent {
  readonly #venueService = inject(VenueService);
  readonly #router = inject(Router);

  sidebarCollapsed = input<boolean>(false);

  @HostBinding('class.sidebar-collapsed') get isCollapsed() {
    return this.sidebarCollapsed();
  }

  protected compareModelsFn = compareModelsFn;

  protected pages = PagesEnum;
  protected iconTypeEnum = IconTypeEnum;
  protected mainLinks = MAIN_LINKS;
  protected venueLinks = VENUE_LINKS;
  protected settingsLinks = SETTINGS_LINKS;
  protected appName = WAYTR;

  protected readonly selectedVenue = this.#venueService.selectedVenue;
  protected readonly userVenues = this.#venueService.userVenues;

  protected elementLoaded = true;

  protected openMap: { [name: string]: boolean } = {
    main: true,
    settings: false,
  };

  isCurrentUrl(url: string): boolean {
    return this.#router.url.includes(url);
  }

  sublinkContainsCurrentUrl(sublinks: AppLinksTypeModel[] | undefined): boolean {
    return sublinks?.some(sublink => this.isCurrentUrl(sublink.route)) || false;
  }

  constructor() {
    effect(() => {
      this.elementLoaded = !!this.selectedVenue();
    });
  }

  valueChanged(value: VenueModelResponseDto) {
    this.#venueService.setSelectedVenue(value);
  }

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }
}
