@defer {
  <img
    [@fadeAnimation]
    class="background non-selectable"
    ngSrc="assets/img/app/gradient.png"
    fill
    priority
    [alt]="'waytr-background' | translate" />
}
<aside class="navigation" [@fadeAnimation]>
  <app-side-menu [sidebarCollapsed]="isSidebarCollapsed"></app-side-menu>
</aside>
<span
  [@addAnimation]
  [@removeAnimation]
  nz-icon
  nzType="menu"
  nzTheme="outline"
  class="collapse-button clickable"
  [title]="isSidebarCollapsed ? ('open' | translate) : ('close' | translate) + ' sidebar'"
  tabindex="0"
  (click)="sidebarCollapsedClicked.set(!sidebarCollapsedClicked())"
  (keyup)="sidebarCollapsedClicked.set(!sidebarCollapsedClicked())"></span>
<main class="container">
  <app-main></app-main>
</main>
