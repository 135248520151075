import {
  APP_ID,
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  PLATFORM_ID,
  importProvidersFrom,
  inject,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  Router,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import en from '@angular/common/locales/en';
import ro from '@angular/common/locales/ro';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions, withI18nSupport } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { NZ_I18N, en_US, ro_RO } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { appInitializerFactory } from './app.component';
import { routes } from './app.routes';
import { ENGLISH_US, ROMANIAN } from './constants';
import { AuthGuard } from './guards';
import { ApiKeyInterceptor, JwtInterceptor, RefreshTokenInterceptor } from './interceptors';
import {
  AppUpdateModelResponseDto,
  CartItemModelResponseDto,
  NotificationModelResponseDto,
  OrderModelResponseDto,
  SessionModelResponseDto,
  SuborderModelResponseDto,
  VenueModelResponseDto,
  WaiterCommandModelResponseDto,
} from './models';
import {
  APP_UPDATES_WEBSOCKET_SERVICE,
  ApiService,
  CART_ITEMS_WEBSOCKET_SERVICE,
  LocalStorageService,
  NOTIFICATIONS_WEBSOCKET_SERVICE,
  ORDERS_WEBSOCKET_SERVICE,
  SESSIONS_WEBSOCKET_SERVICE,
  SUBORDERS_WEBSOCKET_SERVICE,
  TitleService,
  VENUE_WEBSOCKET_SERVICE,
  WAITER_COMMANDS_VENUES_WEBSOCKET_SERVICE,
  WAITER_COMMANDS_WEBSOCKET_SERVICE,
  WebsocketService,
} from './services';
import { LocalesService } from './services/locales.service';
import { WaytrIconsService } from './shared';
import { FlagBasedPreloadingStrategy } from './shared/strategy';

registerLocaleData(en);
registerLocaleData(ro);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.warn(`Missing translation for key: ${params.key}`);
    return params.key;
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withPreloading(FlagBasedPreloadingStrategy),
      withViewTransitions(),
      withComponentInputBinding(),
    ),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    // Experimental
    provideExperimentalZonelessChangeDetection(),
    provideClientHydration(
      withEventReplay(),
      withI18nSupport(),
      withHttpTransferCacheOptions({ includeHeaders: ['authorization', 'api-key'], includeRequestsWithAuthHeaders: true }),
    ),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: ENGLISH_US,
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MyMissingTranslationHandler,
        },
        useDefaultLang: true,
      }),
      NzIconModule.forChild(icons),
    ]),
    { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: APP_ID, useValue: 'waytr-dashboard' },
    {
      provide: NZ_I18N,
      useFactory: () => {
        const localId = inject(LOCALE_ID);
        switch (localId) {
          case ENGLISH_US:
            return en_US;
          /** keep the same with angular.json/i18n/locales configuration **/
          case ROMANIAN:
            return ro_RO;
          default:
            return en_US;
        }
      },
    },
    ApiService,
    AuthGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TitleService, LocalesService, LocalStorageService, TranslateService, WaytrIconsService, PLATFORM_ID],
      multi: true,
    },
    // Create multiple instances of WebsocketService for each domain
    {
      provide: APP_UPDATES_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<AppUpdateModelResponseDto>('app-updates'),
    },
    {
      provide: CART_ITEMS_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<CartItemModelResponseDto>('cart-items'),
    },
    {
      provide: ORDERS_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<OrderModelResponseDto>('orders'),
    },
    {
      provide: SUBORDERS_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<SuborderModelResponseDto>('suborders'),
    },
    {
      provide: SESSIONS_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<SessionModelResponseDto>('sessions'),
    },
    {
      provide: NOTIFICATIONS_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<NotificationModelResponseDto>('notifications'),
    },
    {
      provide: WAITER_COMMANDS_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<WaiterCommandModelResponseDto>('waiter_commands'),
    },
    {
      provide: WAITER_COMMANDS_VENUES_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<WaiterCommandModelResponseDto>('waiter_commands_venues'),
    },
    {
      provide: VENUE_WEBSOCKET_SERVICE,
      useFactory: () => new WebsocketService<VenueModelResponseDto>('venue'),
    },
  ],
};
